* {
  font-family: 'Quicksand', sans-serif;
}

@import url('https://fonts.cdnfonts.com/css/gentax');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');


.bgMain {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9491968662464986) 0%, rgba(0, 0, 0, 0.7139027485994398) 51%, rgba(0, 0, 0, 0.9688047093837535) 100%), url('./assets/3367248.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mintA{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
 }

.t2S {
  color: #8b7245;
}

.loadingText {
  color: white;
  text-align: center;
}

.success-message {
  text-align: center;
  color: greenyellow;
}

.web3 {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.crossMint {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.bold {
  font-weight: bold;
}

.disabled {
  cursor: no-drop;
}

.navIcon {
  margin-top: auto;
  margin-bottom: auto;
}

#font {
  margin-top: auto;
  margin-bottom: auto;
}

.onNav {
  color: #e2a74e;
  font-weight: bold;
}

.allWrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: max-content;
  background-color: black;
}

.light2 {
  min-height: 100vh;
  height: max-content;
  overflow: hidden;
}

@media screen and (max-width: 499px) {
  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.3em;
    color: rgba(223,190,106,0.7);
    border-radius: 0;
    padding: 18px 80px 20px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223,190,106,0.8), rgba(146,111,52,0.8), rgba(34,34,34,0), rgba(34,34,34,0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: none;
    border: 1px solid rgba(223,190,106,0.3);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
    width: 90%;
  }
  
  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223,190,106,0);
    background-position: 99% 50%;
  }
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .nftPic {
    width: 80%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8%;
  }

  .arrow {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

  }

  .miniBar {
    display: flex;
    justify-content: space-between;
    background-color: #3c79b300;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    font-family: 'DM Sans';
    color: white;
    border-bottom: 1px solid rgba(196, 144, 34, 0.164);
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #f8b24950 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .navLogo {
    width: 85px;
  }

  .navDiv {
    display: flex;
    justify-content: center;
  }

  .navDiv div {
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: rgb(255, 255, 255);
  }

  .navDiv div:hover {
    color: #f2bb61;
  }

  .navIcon img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: transform .2s;
  }

  .navIcon img:hover {
    transform: scale(1.1);
  }

  .walletBtn {
    background: rgba(255, 255, 255, 0.116);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #f2bb61;
    padding: 10px;
    border-radius: 100px;
    color: white;
    width: 200px;
    transition: 0.2s ease-in-out;
  }

  .walletBtn:hover {
    color: #b58f52;
    box-shadow: 0 0 5px 7px #f2ba6136;

  }

  .cont {
    height: max-content;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7895330007002801) 0%, rgba(0, 0, 0, 0.5318299194677871) 51%, rgba(0, 0, 0, 0.9688047093837535) 100%), url('./assets/3474997.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: #0f2322;
  }

  .introduction {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: 110px;
  }

  .introduction img {
    width: 95%;
    height: 95%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 30px;
  }

  .in1 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }

  .gradient {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
  }

  .in2 {
    color: rgba(255, 255, 255, 0.651);
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 25px;
    margin-bottom: 30px;
    text-align: center;
  }

  #errorMessage {
    color: #ff6565;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
  }

  #loadingText {
    color: #b9b9b9;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
  }

  #success-message {
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
  }

  .container .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 200px;
    height: 45px;
    margin: 0;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .container .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 10px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
  }

  .container .btn:hover a {
    letter-spacing: 3px;
  }

  .container .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
  }

  .container .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
  }

  .container .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::before

  /*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::after

  /*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn:nth-child(1)::before,
  /*chnage 1*/
  .container .btn:nth-child(1)::after {
    background: #b58f52;
    box-shadow: 0 0 5px #b58f52, 0 0 15px #b58f52, 0 0 30px #b58f52,
      0 0 60px #b58f52;
  }

  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    margin-top: 80px;
  }

  .title {
    font-size: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, #024d2d 22%, #7d9b6c 24%, #024d2d 26%, #ceab4d 27%, #ceab4d 40%, #00331d 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    text-decoration: underline 3px #997c43;
  }

  .aboutMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 14px;
    color: rgba(149, 211, 183, 0.644);
  }

  .daoMainBg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .daoMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    margin-top: 80px;
  }

  .whyMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .visionMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 80px;
  }

  .daoMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 14px;
    color: rgba(149, 211, 183, 0.644);
  }

  .whyMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 14px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    background: rgba(240, 240, 240, 0.075);
    padding: 15px;
    width: 100%;
    color: rgba(189, 186, 186, 0.644);
    text-shadow: 0 0 10px #ffffff80;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff80;
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.5234265581232493) 0%, rgba(146, 175, 89, 0.5234265581232493) 100%);
  }

  .content3Main {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .content3 {
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.3749671743697479) 0%, rgba(103, 124, 61, 0.39457501750700286) 48%, rgba(206, 171, 77, 0.3889727766106442) 95%);
    color: rgba(230, 255, 244, 0.644);
    padding: 20px;
    border-radius: 20px;

  }

  .mainCards {
    display: flex;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .mainCardsSub {
    color: #ffe4b588;
    background: rgb(184, 126, 35);
    background: linear-gradient(94deg, rgba(184, 126, 35, 0.13967305672268904) 0%, rgba(242, 187, 97, 0.061241684173669486) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #b58f5265;
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mainCardsSub img {
    width: 100%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mainCardsSub div {
    font-size: 16px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .visionSub {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;

  }

  .visionSub img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .visionSub div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 17px;
    text-align: center;
    color: rgba(149, 211, 183, 0.644);
    margin-top: 30px;
  }

  li::marker {
    color: #634f2c;
  }


  .textButton {
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .svAbout {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
  }

  .gradient2 {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 20px;
    width: 100%;
  }

  .svAbout div {
    margin-right: auto;
    margin-left: auto;
    width: 95%;
    font-size: 14px;
    text-align: center;
    color: rgba(149, 211, 183, 0.644);
  }

  .videoWidth {
    width: 90%;
    height: 315px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .vidT {
    font-weight: bolder;
  }

  .mintBoxMain {
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: 5%;
  }

  .nft1 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nft2 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mintBox div {
    text-align: center;
    font-size: 22px;
    color: #cae078;
    text-shadow: 0 0 10px #174b2e;
    font-family: 'Gentax', sans-serif;
  }

  .mintBox button {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease-in-out;
  }

  .mintBox button:hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: rgb(136, 136, 136);
  }

  .footer {
    background-color: #181818;
    color: #f2bb61;
    padding: 30px 0;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    background: linear-gradient(180deg, rgba(0,0,0,0.8651632528011204) 0%, rgba(0,0,0,0.5178243172268908) 51%, rgba(0,0,0,0.8819699754901961) 100%), url('./assets/3_asdasf112.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .footer img {
    width: 100px;
  }

  .footer a {
    color: #f2bb61;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer a:hover {
    color: #ffd04e;
    /* Change to desired hover color */
  }

  .footer p {
    margin: 10px 0;
    font-size: 14px;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.3em;
    color: rgba(223,190,106,0.7);
    border-radius: 0;
    padding: 18px 80px 20px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223,190,106,0.8), rgba(146,111,52,0.8), rgba(34,34,34,0), rgba(34,34,34,0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: none;
    border: 1px solid rgba(223,190,106,0.3);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
    width: 90%;
  }
  
  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223,190,106,0);
    background-position: 99% 50%;
  }
  .nftPic {
    width: 80%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .nftminter2 {
    color: #ffa630;
    font-size: 45px;
  }

  .arrow {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 85%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

  }

  .miniBar {
    display: flex;
    justify-content: space-between;
    background-color: #3c79b300;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 45px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 23px;
    margin-right: 15px;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    font-family: 'DM Sans';
    color: white;
    border-bottom: 1px solid rgba(196, 144, 34, 0.164);
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #f8b24950 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .navLogo {
    width: 95px;
  }

  .navDiv {
    display: flex;
    justify-content: center;
  }

  .navDiv div {
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: rgb(255, 255, 255);
  }

  .navDiv div:hover {
    color: #f2bb61;
  }

  .navIcon img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: transform .2s;
  }

  .navIcon img:hover {
    transform: scale(1.1);
  }

  .walletBtn {
    background: rgba(255, 255, 255, 0.116);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #f2bb61;
    padding: 10px;
    border-radius: 100px;
    color: white;
    width: 200px;
    transition: 0.2s ease-in-out;
  }

  .walletBtn:hover {
    color: #b58f52;
    box-shadow: 0 0 5px 7px #f2ba6136;

  }

  .cont {
    height: max-content;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7895330007002801) 0%, rgba(0, 0, 0, 0.5318299194677871) 51%, rgba(0, 0, 0, 0.9688047093837535) 100%), url('./assets/3474997.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: #0f2322;
  }

  .introduction {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: 110px;
  }

  .introduction img {
    width: 90%;
    height: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 30px;
  }

  .in1 {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
  }

  .gradient {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
  }

  .in2 {
    color: rgba(255, 255, 255, 0.651);
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 25px;
    margin-bottom: 30px;
    text-align: center;
  }

  #errorMessage {
    color: #ff6565;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  #loadingText {
    color: #b9b9b9;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  #success-message {
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  .container .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 200px;
    height: 45px;
    margin: 0;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .container .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 10px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
  }

  .container .btn:hover a {
    letter-spacing: 3px;
  }

  .container .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
  }

  .container .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
  }

  .container .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::before

  /*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::after

  /*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn:nth-child(1)::before,
  /*chnage 1*/
  .container .btn:nth-child(1)::after {
    background: #b58f52;
    box-shadow: 0 0 5px #b58f52, 0 0 15px #b58f52, 0 0 30px #b58f52,
      0 0 60px #b58f52;
  }

  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    margin-top: 80px;
  }

  .title {
    font-size: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, #024d2d 22%, #7d9b6c 24%, #024d2d 26%, #ceab4d 27%, #ceab4d 40%, #00331d 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    text-decoration: underline 4px #997c43;
  }

  .aboutMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 16px;
    color: rgba(149, 211, 183, 0.644);
  }

  .daoMainBg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .daoMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    margin-top: 80px;
  }

  .whyMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .visionMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 80px;
  }

  .daoMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    color: rgba(149, 211, 183, 0.644);
  }

  .whyMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    background: rgba(240, 240, 240, 0.075);
    padding: 15px;
    width: 100%;
    color: rgba(189, 186, 186, 0.644);
    text-shadow: 0 0 10px #ffffff80;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff80;
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.5234265581232493) 0%, rgba(146, 175, 89, 0.5234265581232493) 100%);
  }

  .content3Main {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .content3 {
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.3749671743697479) 0%, rgba(103, 124, 61, 0.39457501750700286) 48%, rgba(206, 171, 77, 0.3889727766106442) 95%);
    color: rgba(230, 255, 244, 0.644);
    padding: 20px;
    border-radius: 20px;

  }



  .mainCards {
    display: flex;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .mainCardsSub {
    color: #ffe4b588;
    background: rgb(184, 126, 35);
    background: linear-gradient(94deg, rgba(184, 126, 35, 0.13967305672268904) 0%, rgba(242, 187, 97, 0.061241684173669486) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #b58f5265;
    width: 60%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mainCardsSub img {
    width: 100%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mainCardsSub div {
    font-size: 16px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .visionSub {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;

  }

  .visionSub img {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -50px;
  }

  .visionSub div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 17px;
    text-align: center;
    color: rgba(149, 211, 183, 0.644);
    margin-top: 30px;
  }

  li::marker {
    color: #634f2c;
  }


  .textButton {
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .svAbout {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
  }

  .gradient2 {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 30px;
    width: 100%;
  }

  .svAbout div {
    margin-right: auto;
    margin-left: auto;
    width: 95%;
    font-size: 18px;
    text-align: center;
    color: rgba(149, 211, 183, 0.404);
  }

  .videoWidth {
    width: 500px;
    height: 300px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .vidT {
    font-weight: bolder;
  }

  .mintBoxMain {
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: 5%;
  }

  .nft1 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nft2 {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mintBox div {
    text-align: center;
    font-size: 22px;
    color: #cae078;
    text-shadow: 0 0 10px #174b2e;
    font-family: 'Gentax', sans-serif;
  }

  .mintBox button {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease-in-out;
  }

  .mintBox button:hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: rgb(136, 136, 136);
  }

  .footer {
    background-color: #181818;
    color: #f2bb61;
    padding: 30px 0;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    background: linear-gradient(180deg, rgba(0,0,0,0.8651632528011204) 0%, rgba(0,0,0,0.5178243172268908) 51%, rgba(0,0,0,0.8819699754901961) 100%), url('./assets/3_asdasf112.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .footer img {
    width: 120px;
  }

  .footer a {
    color: #f2bb61;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer a:hover {
    color: #ffd04e;
    /* Change to desired hover color */
  }

  .footer p {
    margin: 10px 0;
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .nftPic {
    width: 50%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.3em;
    color: rgba(223,190,106,0.7);
    border-radius: 0;
    padding: 18px 80px 20px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223,190,106,0.8), rgba(146,111,52,0.8), rgba(34,34,34,0), rgba(34,34,34,0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: none;
    border: 1px solid rgba(223,190,106,0.3);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
    width: 50%;
  }
  
  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223,190,106,0);
    background-position: 99% 50%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .nftminter2 {
    color: #ffa630;
    font-size: 45px;
  }

  .arrow {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 30px;
    width: 85%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

  }

  .miniBar {
    display: flex;
    justify-content: space-between;
    background-color: #3c79b300;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 45px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 23px;
    margin-right: 15px;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    font-family: 'DM Sans';
    color: white;
    border-bottom: 1px solid rgba(196, 144, 34, 0.164);
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #f8b24950 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .navLogo {
    width: 95px;
  }

  .navDiv {
    display: flex;
    justify-content: center;
  }

  .navDiv div {
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: rgb(255, 255, 255);
  }

  .navDiv div:hover {
    color: #f2bb61;
  }

  .navIcon img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: transform .2s;
  }

  .navIcon img:hover {
    transform: scale(1.1);
  }

  .walletBtn {
    background: rgba(255, 255, 255, 0.116);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #f2bb61;
    padding: 10px;
    border-radius: 100px;
    color: white;
    width: 200px;
    transition: 0.2s ease-in-out;
  }

  .walletBtn:hover {
    color: #b58f52;
    box-shadow: 0 0 5px 7px #f2ba6136;

  }

  .cont {
    height: max-content;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7895330007002801) 0%, rgba(0, 0, 0, 0.5318299194677871) 51%, rgba(0, 0, 0, 0.9688047093837535) 100%), url('./assets/3474997.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: #0f2322;
  }

  .introduction {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: 110px;
  }

  .introduction img {
    width: 90%;
    height: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 30px;
  }

  .in1 {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
  }

  .gradient {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
  }

  .in2 {
    color: rgba(255, 255, 255, 0.651);
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 25px;
    margin-bottom: 30px;
    text-align: center;
  }

  #errorMessage {
    color: #ff6565;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  #loadingText {
    color: #b9b9b9;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  #success-message {
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  .container .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 200px;
    height: 45px;
    margin: 0;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .container .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 10px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
  }

  .container .btn:hover a {
    letter-spacing: 3px;
  }

  .container .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
  }

  .container .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
  }

  .container .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::before

  /*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::after

  /*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn:nth-child(1)::before,
  /*chnage 1*/
  .container .btn:nth-child(1)::after {
    background: #b58f52;
    box-shadow: 0 0 5px #b58f52, 0 0 15px #b58f52, 0 0 30px #b58f52,
      0 0 60px #b58f52;
  }

  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    margin-top: 80px;
  }

  .title {
    font-size: 45px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, #024d2d 22%, #7d9b6c 24%, #024d2d 26%, #ceab4d 27%, #ceab4d 40%, #00331d 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    text-decoration: underline 4px #997c43;
  }

  .aboutMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 16px;
    color: rgba(149, 211, 183, 0.644);
  }

  .daoMainBg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .daoMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    margin-top: 80px;
  }

  .whyMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
  }

  .visionMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 80px;
  }

  .daoMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    color: rgba(149, 211, 183, 0.644);
  }

  .whyMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    background: rgba(240, 240, 240, 0.075);
    padding: 15px;
    width: 100%;
    color: rgba(189, 186, 186, 0.644);
    text-shadow: 0 0 10px #ffffff80;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff80;
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.5234265581232493) 0%, rgba(146, 175, 89, 0.5234265581232493) 100%);
  }

  .content3Main {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .content3 {
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.3749671743697479) 0%, rgba(103, 124, 61, 0.39457501750700286) 48%, rgba(206, 171, 77, 0.3889727766106442) 95%);
    color: rgba(230, 255, 244, 0.644);
    padding: 20px;
    border-radius: 20px;
  }


  .mainCards {
    display: flex;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .mainCardsSub {
    color: #ffe4b588;
    background: rgb(184, 126, 35);
    background: linear-gradient(94deg, rgba(184, 126, 35, 0.13967305672268904) 0%, rgba(242, 187, 97, 0.061241684173669486) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #b58f5265;
    width: 40%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mainCardsSub img {
    width: 100%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mainCardsSub div {
    font-size: 16px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .visionSub {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;

  }

  .visionSub img {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -50px;
  }

  .visionSub div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 17px;
    text-align: center;
    color: rgba(149, 211, 183, 0.644);
    margin-top: 30px;
  }

  li::marker {
    color: #634f2c;
  }


  .textButton {
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .svAbout {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
  }

  .gradient2 {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 35px;
    width: 100%;
  }

  .svAbout div {
    margin-right: auto;
    margin-left: auto;
    width: 95%;
    font-size: 16px;
    text-align: left;
    color: rgba(149, 211, 183, 0.404);
    text-align: center;
  }

  .videoWidth {
    width: 500px;
    height: 300px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .vidT {
    font-weight: bolder;
  }

  .mintBoxMain {
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: 5%;
  }

  .nft1 {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nft2 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mintBox div {
    text-align: center;
    font-size: 22px;
    color: #cae078;
    text-shadow: 0 0 10px #174b2e;
    font-family: 'Gentax', sans-serif;
  }

  .mintBox button {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease-in-out;
  }

  .mintBox button:hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: rgb(136, 136, 136);
  }

  .footer {
    background-color: #181818;
    color: #f2bb61;
    padding: 30px 0;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    background: linear-gradient(180deg, rgba(0,0,0,0.8651632528011204) 0%, rgba(0,0,0,0.5178243172268908) 51%, rgba(0,0,0,0.8819699754901961) 100%), url('./assets/3_asdasf112.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .footer img {
    width: 120px;
  }

  .footer a {
    color: #f2bb61;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer a:hover {
    color: #ffd04e;
    /* Change to desired hover color */
  }

  .footer p {
    margin: 10px 0;
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 5%;
    margin-bottom: 8%;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0.3em;
    color: rgba(223,190,106,0.7);
    border-radius: 0;
    padding: 18px 80px 20px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223,190,106,0.8), rgba(146,111,52,0.8), rgba(34,34,34,0), rgba(34,34,34,0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: none;
    border: 1px solid rgba(223,190,106,0.3);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }
  
  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223,190,106,0);
    background-position: 99% 50%;
  }
  .nftPic {
    width: 33%;
    border-radius: 100%;
  }

  .nftminter2 {
    color: #ffa630;
    font-size: 40px;
  }

  .arrow {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .miniBarMain {
    display: none;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .navLogo {
    width: 95px;
  }

  .navDiv {
    display: flex;
    justify-content: center;
  }

  .navDiv div {
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: rgb(255, 255, 255);
  }

  .navDiv div:hover {
    color: #f2bb61;
  }

  .navIcon img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: transform .2s;
  }

  .navIcon img:hover {
    transform: scale(1.1);
  }

  .walletBtn {
    background: rgba(255, 255, 255, 0.116);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #f2bb61;
    padding: 10px;
    border-radius: 100px;
    color: white;
    width: 200px;
    transition: 0.2s ease-in-out;
  }

  .walletBtn:hover {
    color: #b58f52;
    box-shadow: 0 0 5px 7px #f2ba6136;

  }

  .cont {
    height: max-content;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7895330007002801) 0%, rgba(0, 0, 0, 0.5318299194677871) 51%, rgba(0, 0, 0, 0.9688047093837535) 100%), url('./assets/3474997.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: #0f2322;
  }

  .introduction {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width: 90%;
    margin-top: 12%;

  }

  .introduction img {
    width: 45%;
    height: 45%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .in1 {
    font-size: 40px;
    font-weight: 600;
  }

  .gradient {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
  }

  .in2 {
    color: rgba(255, 255, 255, 0.651);
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 3%;
    margin-bottom: 10%;
  }

  #errorMessage {
    color: #ff6565;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  #loadingText {
    color: #b9b9b9;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  #success-message {
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  .container .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 200px;
    height: 45px;
    margin: 0;
    font-size: 18px;
  }

  .container .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 10px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
  }

  .container .btn:hover a {
    letter-spacing: 3px;
  }

  .container .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
  }

  .container .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
  }

  .container .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::before

  /*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::after

  /*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn:nth-child(1)::before,
  /*chnage 1*/
  .container .btn:nth-child(1)::after {
    background: #b58f52;
    box-shadow: 0 0 5px #b58f52, 0 0 15px #b58f52, 0 0 30px #b58f52,
      0 0 60px #b58f52;
  }

  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    margin-top: 10%;
  }

  .title {
    font-size: 45px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, #024d2d 22%, #7d9b6c 24%, #024d2d 26%, #ceab4d 27%, #ceab4d 40%, #00331d 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    text-decoration: underline 4px #997c43;
  }

  .aboutMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 16px;
    color: rgba(149, 211, 183, 0.644);
  }

  .daoMainBg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .daoMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    margin-top: 10%;
  }

  .whyMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .visionMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10%;
  }

  .daoMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    color: rgba(149, 211, 183, 0.644);
  }

  .whyMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    background: rgba(240, 240, 240, 0.075);
    padding: 15px;
    width: 90%;
    color: rgba(189, 186, 186, 0.644);
    text-shadow: 0 0 10px #ffffff80;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff80;
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.5234265581232493) 0%, rgba(146, 175, 89, 0.5234265581232493) 100%);
  }

  .content3Main {
    width: 30%;

  }

  .content3 {
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.3749671743697479) 0%, rgba(103, 124, 61, 0.39457501750700286) 48%, rgba(206, 171, 77, 0.3889727766106442) 95%);
    color: rgba(230, 255, 244, 0.644);
    padding: 20px;
    border-radius: 20px;
  }



  .mainCards {
    display: flex;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .mainCardsSub {
    color: #ffe4b588;
    background: rgb(184, 126, 35);
    background: linear-gradient(94deg, rgba(184, 126, 35, 0.13967305672268904) 0%, rgba(242, 187, 97, 0.061241684173669486) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #b58f5265;
    width: 28%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px;
  }

  .mainCardsSub img {
    width: 100%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mainCardsSub div {
    font-size: 16px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .visionSub {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .visionSub img {
    width: 50%;
  }

  .visionSub div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 17px;
    text-align: left;
    color: rgba(149, 211, 183, 0.644);
  }

  li::marker {
    color: #634f2c;
  }


  .textButton {
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .svAbout {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .gradient2 {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 25px;
    width: 100%;
  }

  .svAbout div {
    margin-top: auto;
    margin-bottom: auto;
    width: 40%;
    font-size: 16px;
    text-align: left;
    color: rgba(149, 211, 183, 0.404);
    margin-top: 5%;
  }

  .videoWidth {
    width: 400px;
    height: 315px;
  }

  .vidT {
    font-weight: bolder;
  }

  .mintBoxMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .nft1 {
    width: 300px;
  }

  .nft2 {
    width: 250px;
  }

  .mintBox div {
    text-align: center;
    font-size: 22px;
    color: #cae078;
    text-shadow: 0 0 10px #174b2e;
    font-family: 'Gentax', sans-serif;

  }

  .mintBox button {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease-in-out;
  }

  .mintBox button:hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: rgb(136, 136, 136);
  }

  .footer {
    background-color: #181818;
    color: #f2bb61;
    padding: 30px 0;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    background: linear-gradient(180deg, rgba(0,0,0,0.8651632528011204) 0%, rgba(0,0,0,0.5178243172268908) 51%, rgba(0,0,0,0.8819699754901961) 100%), url('./assets/3_asdasf112.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .footer img {
    width: 150px;
  }

  .footer a {
    color: #f2bb61;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer a:hover {
    color: #ffd04e;
    /* Change to desired hover color */
  }

  .footer p {
    margin: 10px 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .nftPic {
    width: 33%;
    border-radius: 100%;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.3em;
    color: rgba(223,190,106,0.7);
    border-radius: 0;
    padding: 18px 80px 20px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223,190,106,0.8), rgba(146,111,52,0.8), rgba(34,34,34,0), rgba(34,34,34,0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: none;
    border: 1px solid rgba(223,190,106,0.3);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }
  
  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223,190,106,0);
    background-position: 99% 50%;
  }
  #errorMessage {
    color: #ff6565;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  #loadingText {
    color: #b9b9b9;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  #success-message {
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 5%;
    margin-bottom: 8%;
  }

  .arrow {
    width: 40px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .nftminter2 {
    color: #ffa630;
    font-size: 40px;
  }

  .miniBarMain {
    display: none;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .navLogo {
    width: 100px;
  }

  .navDiv {
    display: flex;
    justify-content: center;
  }

  .navDiv div {
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: rgb(255, 255, 255);
  }

  .navDiv div:hover {
    color: #f2bb61;
  }

  .navIcon img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: transform .2s;
  }

  .navIcon img:hover {
    transform: scale(1.1);
  }

  .walletBtn {
    background: rgba(255, 255, 255, 0.116);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #f2bb61;
    padding: 10px;
    border-radius: 100px;
    color: white;
    width: 200px;
    transition: 0.2s ease-in-out;
  }

  .walletBtn:hover {
    color: #b58f52;
    box-shadow: 0 0 5px 7px #f2ba6136;

  }

  .cont {
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7895330007002801) 0%, rgba(0, 0, 0, 0.5318299194677871) 51%, rgba(0, 0, 0, 0.9688047093837535) 100%), url('./assets/3474997.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: #0f2322;
  }

  .introduction {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width: 90%;
    margin-top: 12%;

  }

  .introduction img {
    width: 45%;
    height: 45%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .in1 {
    font-size: 40px;
    font-weight: 600;
  }

  .gradient {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
  }

  .in2 {
    color: rgba(255, 255, 255, 0.651);
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 3%;
    margin-bottom: 10%;
  }

  .container .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 250px;
    height: 50px;
    margin: 0;
  }

  .container .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 10px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
    font-size: 18px;
  }

  .container .btn:hover a {
    letter-spacing: 3px;
  }

  .container .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
  }

  .container .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
  }

  .container .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::before

  /*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::after

  /*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn:nth-child(1)::before,
  /*chnage 1*/
  .container .btn:nth-child(1)::after {
    background: #b58f52;
    box-shadow: 0 0 5px #b58f52, 0 0 15px #b58f52, 0 0 30px #b58f52,
      0 0 60px #b58f52;
  }

  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 60%;
    margin-top: 10%;
  }

  .title {
    font-size: 50px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, #024d2d 22%, #7d9b6c 24%, #024d2d 26%, #ceab4d 27%, #ceab4d 40%, #00331d 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    text-decoration: underline 5px #997c43;
  }

  .aboutMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 18px;
    color: rgba(149, 211, 183, 0.644);
  }

  .daoMainBg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .daoMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 60%;
    margin-top: 10%;
  }

  .whyMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .visionMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10%;
  }

  .daoMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    color: rgba(149, 211, 183, 0.644);
  }

  .whyMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    padding: 15px;
    width: 100%;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff80;
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.5234265581232493) 0%, rgba(146, 175, 89, 0.5234265581232493) 100%);
  }

  .content3Main {
    width: 30%;

  }

  .content3 {
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.3749671743697479) 0%, rgba(103, 124, 61, 0.39457501750700286) 48%, rgba(206, 171, 77, 0.3889727766106442) 95%);
    color: rgba(230, 255, 244, 0.644);
    padding: 20px;
    border-radius: 20px;
  }


  .mainCards {
    display: flex;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .mainCardsSub {
    color: #ffe4b588;
    background: rgb(184, 126, 35);
    background: linear-gradient(94deg, rgba(184, 126, 35, 0.13967305672268904) 0%, rgba(242, 187, 97, 0.061241684173669486) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #b58f5265;
    width: 25%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px;
  }

  .mainCardsSub img {
    width: 100%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mainCardsSub div {
    font-size: 16px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .visionSub {
    display: flex;
    justify-content: space-evenly;
    margin-top: -100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .visionSub img {
    width: 50%;
  }

  .visionSub div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 17px;
    text-align: left;
    color: rgba(149, 211, 183, 0.644);
  }

  li::marker {
    color: #634f2c;
  }


  .textButton {
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .svAbout {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1%;
  }

  .gradient2 {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 35px;
    width: 100%;
  }

  .svAbout div {
    margin-top: auto;
    margin-bottom: auto;
    width: 40%;
    font-size: 16px;
    text-align: left;
    color: rgba(149, 211, 183, 0.404);
  }

  .videoWidth {
    width: 560px;
    height: 315px;
  }

  .vidT {
    font-weight: bolder;
  }

  .mintBoxMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: 7%;
  }

  .nft1 {
    width: 400px;
   }

  .nft2 {
    width: 320px;
   }

  .mintBox div {
    text-align: center;
    font-size: 25px;
    color: #cae078;
    text-shadow: 0 0 10px #174b2e;
    font-family: 'Gentax', sans-serif;

  }

  .mintBox button {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease-in-out;
  }

  .mintBox button:hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: rgb(136, 136, 136);
  }

  .footer {
    background-color: #181818;
    color: #f2bb61;
    padding: 50px 0;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    background: linear-gradient(180deg, rgba(0,0,0,0.8651632528011204) 0%, rgba(0,0,0,0.5178243172268908) 51%, rgba(0,0,0,0.8819699754901961) 100%), url('./assets/3_asdasf112.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .footer img {
    width: 150px;
  }

  .footer a {
    color: #f2bb61;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer a:hover {
    color: #ffd04e;
    /* Change to desired hover color */
  }

  .footer p {
    margin: 10px 0;
  }
}

@media screen and (min-width: 1920px) {
  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    letter-spacing: 0.3em;
    color: rgba(223,190,106,0.7);
    border-radius: 0;
    padding: 22px 80px 24px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223,190,106,0.8), rgba(146,111,52,0.8), rgba(34,34,34,0), rgba(34,34,34,0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: none;
    border: 1px solid rgba(223,190,106,0.3);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }
  
  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223,190,106,0);
    background-position: 99% 50%;
  }
  .nftPic {
    width: 33%;
    border-radius: 100%;
  }

  #errorMessage {
    color: #ff6565;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
  }

  #loadingText {
    color: #b9b9b9;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  #success-message {
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 5%;
    margin-bottom: 8%;
  }

  .nftminter2 {
    color: #ffa630;
    font-size: 55px;
  }

  .arrow {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .miniBarMain {
    display: none;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .navLogo {
    width: 145px;
  }

  .navDiv {
    display: flex;
    justify-content: center;
  }

  .navDiv div {
    margin-left: 40px;
    margin-right: 40px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: rgb(255, 255, 255);
    font-size: 24px;
  }

  .navDiv div:hover {
    color: #f2bb61;
  }

  .navIcon img {
    width: 42px;
    height: 42px;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    transition: transform .2s;
  }

  .navIcon img:hover {
    transform: scale(1.1);
  }

  .walletBtn {
    background: rgba(255, 255, 255, 0.116);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #f2bb61;
    padding: 10px;
    border-radius: 100px;
    color: white;
    width: 200px;
    transition: 0.2s ease-in-out;
  }

  .walletBtn:hover {
    color: #b58f52;
    box-shadow: 0 0 5px 7px #f2ba6136;

  }

  .cont {
    height: max-content;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7895330007002801) 0%, rgba(0, 0, 0, 0.5318299194677871) 51%, rgba(0, 0, 0, 0.9688047093837535) 100%), url('./assets/3474997.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: #0f2322;
  }

  .introduction {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width: 90%;
    margin-top: 12%;

  }

  .introduction img {
    width: 45%;
    height: 45%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .in1 {
    font-size: 65px;
    font-weight: 600;
  }

  .gradient {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
  }

  .in2 {
    color: rgba(255, 255, 255, 0.651);
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 3%;
    margin-bottom: 10%;
    font-size: 24px;
  }

  .container .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 300px;
    height: 70px;
    margin: 0;
    font-size: 26px;
  }

  .container .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.3);
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    padding: 15px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
  }

  .container .btn:hover a {
    letter-spacing: 3px;
  }

  .container .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
  }

  .container .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
  }

  .container .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -5px;
    width: 130px;
    height: 2px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::before

  /*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -5px;
    width: 120px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::after

  /*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn:nth-child(1)::before,
  /*chnage 1*/
  .container .btn:nth-child(1)::after {
    background: #b58f52;
    box-shadow: 0 0 5px #b58f52, 0 0 15px #b58f52, 0 0 30px #b58f52,
      0 0 60px #b58f52;
  }

  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 60%;
    margin-top: 10%;
  }

  .title {
    font-size: 70px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, #024d2d 22%, #7d9b6c 24%, #024d2d 26%, #ceab4d 27%, #ceab4d 40%, #00331d 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    text-decoration: underline 7px #997c43;
  }

  .aboutMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    font-size: 26px;
    color: rgba(149, 211, 183, 0.644);
  }

  .daoMainBg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .daoMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 60%;
    margin-top: 10%;
  }

  .whyMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .visionMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10%;
  }

  .daoMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 26px;
    color: rgba(149, 211, 183, 0.644);
  }

  .whyMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 26px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    background: rgba(240, 240, 240, 0.075);
    padding: 21px;
    width: 80%;
    color: rgba(189, 186, 186, 0.644);
    text-shadow: 0 0 10px #ffffff80;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff80;
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.5234265581232493) 0%, rgba(146, 175, 89, 0.5234265581232493) 100%);
  }

  .content3Main {
    width: 30%;

  }

  .content3 {
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.3749671743697479) 0%, rgba(103, 124, 61, 0.39457501750700286) 48%, rgba(206, 171, 77, 0.3889727766106442) 95%);
    color: rgba(230, 255, 244, 0.644);
    padding: 30px;
    border-radius: 20px;
    font-size: 24px;
  }


  .mainCards {
    display: flex;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .mainCardsSub {
    color: #ffe4b588;
    background: rgb(184, 126, 35);
    background: linear-gradient(94deg, rgba(184, 126, 35, 0.13967305672268904) 0%, rgba(242, 187, 97, 0.061241684173669486) 100%);
    border-radius: 18px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #b58f5265;
    width: 25%;
    margin-left: 30px;
    margin-right: 30px;
    padding: 45px;
  }

  .mainCardsSub img {
    width: 100%;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mainCardsSub div {
    font-size: 24px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .visionSub {
    display: flex;
    justify-content: space-evenly;
    margin-top: -130px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .visionSub img {
    width: 50%;
  }

  .visionSub div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 24px;
    text-align: left;
    color: rgba(149, 211, 183, 0.644);
  }

  li::marker {
    color: #634f2c;
  }


  .textButton {
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .svAbout {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1%;
  }

  .gradient2 {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 50px;
    width: 100%;
  }

  .svAbout div {
    margin-top: auto;
    margin-bottom: auto;
    width: 40%;
    font-size: 24px;
    text-align: left;
    color: rgba(149, 211, 183, 0.404);
  }

  .videoWidth {
    width: 750px;
    height: 415px;
  }

  .vidT {
    font-weight: bolder;
  }

  .mintBoxMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .nft1 {
    width: 550px;
  }

  .nft2 {
    width: 480px;
  }

  .mintBox div {
    text-align: center;
    font-size: 35px;
    color: #cae078;
    text-shadow: 0 0 10px #174b2e;
    font-family: 'Gentax', sans-serif;

  }

  .mintBox button {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease-in-out;
  }

  .mintBox button:hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: rgb(136, 136, 136);
  }

  .footer {
    background-color: #181818;
    color: #f2bb61;
    padding: 70px 0;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    background: linear-gradient(180deg, rgba(0,0,0,0.8651632528011204) 0%, rgba(0,0,0,0.5178243172268908) 51%, rgba(0,0,0,0.8819699754901961) 100%), url('./assets/3_asdasf112.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .footer img {
    width: 220px;
  }

  .footer a {
    color: #f2bb61;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer a:hover {
    color: #ffd04e;
    /* Change to desired hover color */
  }

  .footer p {
    margin: 10px 0;
    font-size: 24px;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 30px;
    letter-spacing: 0.3em;
    color: rgba(223,190,106,0.7);
    border-radius: 0;
    padding: 38px 80px 40px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223,190,106,0.8), rgba(146,111,52,0.8), rgba(34,34,34,0), rgba(34,34,34,0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: none;
    border: 1px solid rgba(223,190,106,0.3);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }
  
  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223,190,106,0);
    background-position: 99% 50%;
  }
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 5%;
    margin-bottom: 8%;
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .nftminter2 {
    color: #ffa630;
    font-size: 75px;
  }

  .arrow {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .miniBarMain {
    display: none;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .navLogo {
    width: 200px;
  }

  .navDiv {
    display: flex;
    justify-content: center;
  }

  .navDiv div {
    margin-left: 58px;
    margin-right: 58px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: rgb(255, 255, 255);
    font-size: 32px;
  }

  .navDiv div:hover {
    color: #f2bb61;
  }

  .navIcon img {
    width: 58px;
    height: 58px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    transition: transform .2s;
  }

  .navIcon img:hover {
    transform: scale(1.1);
  }

  .walletBtn {
    background: rgba(255, 255, 255, 0.116);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #f2bb61;
    padding: 10px;
    border-radius: 100px;
    color: white;
    width: 200px;
    transition: 0.2s ease-in-out;
  }

  .walletBtn:hover {
    color: #b58f52;
    box-shadow: 0 0 5px 7px #f2ba6136;

  }

  .cont {
    height: max-content;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7895330007002801) 0%, rgba(0, 0, 0, 0.5318299194677871) 51%, rgba(0, 0, 0, 0.9688047093837535) 100%), url('./assets/3474997.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: #0f2322;
  }

  .introduction {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width: 90%;
    margin-top: 12%;

  }

  .introduction img {
    width: 45%;
    height: 45%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .in1 {
    font-size: 80px;
    font-weight: 600;
  }

  .gradient {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
  }

  .in2 {
    color: rgba(255, 255, 255, 0.651);
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 3%;
    margin-bottom: 10%;
    font-size: 34px;
  }

  #errorMessage {
    color: #ff6565;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 34px;
  }

  #loadingText {
    color: #b9b9b9;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 34px;
  }

  #success-message {
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 34px;
  }


  .container .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 450px;
    height: 100px;
    margin: 0;
    font-size: 34px;
  }

  .container .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.3);
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    padding: 30px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
  }

  .container .btn:hover a {
    letter-spacing: 3px;
  }

  .container .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
  }

  .container .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
  }

  .container .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -10px;
    width: 220px;
    height: 2px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::before

  /*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -10px;
    width: 220px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::after

  /*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn:nth-child(1)::before,
  /*chnage 1*/
  .container .btn:nth-child(1)::after {
    background: #b58f52;
    box-shadow: 0 0 5px #b58f52, 0 0 15px #b58f52, 0 0 30px #b58f52,
      0 0 60px #b58f52;
  }

  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 60%;
    margin-top: 10%;
  }

  .title {
    font-size: 100px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, #024d2d 22%, #7d9b6c 24%, #024d2d 26%, #ceab4d 27%, #ceab4d 40%, #00331d 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    text-decoration: underline 9px #997c43;
  }

  .aboutMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    font-size: 34px;
    color: rgba(149, 211, 183, 0.644);
  }

  .daoMainBg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .daoMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 60%;
    margin-top: 10%;
  }

  .whyMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .visionMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10%;
  }

  .daoMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 34px;
    color: rgba(149, 211, 183, 0.644);
  }

  .whyMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 34px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    background: rgba(240, 240, 240, 0.075);
    padding: 28px;
    width: 80%;
    color: rgba(189, 186, 186, 0.644);
    text-shadow: 0 0 10px #ffffff80;
    text-shadow: 0 0 10px #ffffff80;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff80;
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.5234265581232493) 0%, rgba(146, 175, 89, 0.5234265581232493) 100%);
  }

  .content3Main {
    width: 30%;

  }

  .content3 {
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.3749671743697479) 0%, rgba(103, 124, 61, 0.39457501750700286) 48%, rgba(206, 171, 77, 0.3889727766106442) 95%);
    color: rgba(230, 255, 244, 0.644);
    padding: 50px;
    border-radius: 40px;
    font-size: 34px;
  }


  .mainCards {
    display: flex;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .mainCardsSub {
    color: #ffe4b588;
    background: rgb(184, 126, 35);
    background: linear-gradient(94deg, rgba(184, 126, 35, 0.13967305672268904) 0%, rgba(242, 187, 97, 0.061241684173669486) 100%);
    border-radius: 24px;
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #b58f5265;
    width: 25%;
    margin-left: 40px;
    margin-right: 40px;
    padding: 55px;
  }

  .mainCardsSub img {
    width: 100%;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mainCardsSub div {
    font-size: 34px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .visionSub {
    display: flex;
    justify-content: space-evenly;
    margin-top: -180px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .visionSub img {
    width: 50%;
  }

  .visionSub div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 32px;
    text-align: left;
    color: rgba(149, 211, 183, 0.644);
  }

  li::marker {
    color: #634f2c;
  }


  .textButton {
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .svAbout {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1%;
  }

  .gradient2 {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 60px;
    width: 100%;
  }

  .svAbout div {
    margin-top: auto;
    margin-bottom: auto;
    width: 40%;
    font-size: 32px;
    text-align: left;
    color: rgba(149, 211, 183, 0.404);
  }

  .videoWidth {
    width: 1000px;
    height: 540px;
  }

  .vidT {
    font-weight: bolder;
  }

  .mintBoxMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .nft1 {
    width: 750px;
  }

  .nft2 {
    width: 600px;
  }

  .mintBox div {
    text-align: center;
    font-size: 50px;
    color: #cae078;
    text-shadow: 0 0 10px #174b2e;
    font-family: 'Gentax', sans-serif;

  }

  .mintBox button {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease-in-out;
  }

  .mintBox button:hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: rgb(136, 136, 136);
  }

  .footer {
    background-color: #181818;
    color: #f2bb61;
    padding: 100px 0;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    background: linear-gradient(180deg, rgba(0,0,0,0.8651632528011204) 0%, rgba(0,0,0,0.5178243172268908) 51%, rgba(0,0,0,0.8819699754901961) 100%), url('./assets/3_asdasf112.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .footer img {
    width: 290px;
  }

  .footer a {
    color: #f2bb61;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer a:hover {
    color: #ffd04e;
    /* Change to desired hover color */
  }

  .footer p {
    margin: 25px 0;
    font-size: 34px;
  }
}

@media screen and (min-width: 3840px) {
  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 40px;
    letter-spacing: 0.3em;
    color: rgba(223,190,106,0.7);
    border-radius: 0;
    padding: 48px 80px 50px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223,190,106,0.8), rgba(146,111,52,0.8), rgba(34,34,34,0), rgba(34,34,34,0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    border: none;
    border: 1px solid rgba(223,190,106,0.3);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }
  
  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223,190,106,0);
    background-position: 99% 50%;
  }
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 5%;
    margin-bottom: 8%;
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .nftminter2 {
    color: #ffa630;
    font-size: 100px;
  }

  .arrow {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }


  .miniBarMain {
    display: none;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 45px;
    padding-top: 45px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .navLogo {
    width: 300px;
  }

  .navDiv {
    display: flex;
    justify-content: center;
  }

  .navDiv div {
    margin-left: 80px;
    margin-right: 80px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: rgb(255, 255, 255);
    font-size: 48px;
  }

  .navDiv div:hover {
    color: #f2bb61;
  }

  .navIcon img {
    width: 85px;
    height: 85px;
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    transition: transform .2s;
  }

  .navIcon img:hover {
    transform: scale(1.1);
  }

  .walletBtn {
    background: rgba(255, 255, 255, 0.116);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #f2bb61;
    padding: 10px;
    border-radius: 100px;
    color: white;
    width: 200px;
    transition: 0.2s ease-in-out;
  }

  .walletBtn:hover {
    color: #b58f52;
    box-shadow: 0 0 5px 7px #f2ba6136;

  }

  .cont {
    height: max-content;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7895330007002801) 0%, rgba(0, 0, 0, 0.5318299194677871) 51%, rgba(0, 0, 0, 0.9688047093837535) 100%), url('./assets/3474997.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cont2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: #0f2322;
  }

  .introduction {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width: 90%;
    margin-top: 12%;

  }

  .introduction img {
    width: 45%;
    height: 45%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .in1 {
    font-size: 130px;
    font-weight: 600;
  }

  .gradient {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
  }

  .in2 {
    color: rgba(255, 255, 255, 0.651);
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 3%;
    margin-bottom: 10%;
    font-size: 50px;
  }

  #errorMessage {
    color: #ff6565;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 50px;
  }

  #loadingText {
    color: #b9b9b9;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 50px;
  }

  #success-message {
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 50px;
  }

  .container .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 550px;
    height: 130px;
    margin: 0;
    font-size: 52px;
  }

  .container .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.3);
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    padding: 30px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
  }

  .container .btn:hover a {
    letter-spacing: 3px;
  }

  .container .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
  }

  .container .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
  }

  .container .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -15px;
    width: 300px;
    height: 2px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::before

  /*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -15px;
    width: 300px;
    height: 1px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  .container .btn:hover::after

  /*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  .container .btn:nth-child(1)::before,
  /*chnage 1*/
  .container .btn:nth-child(1)::after {
    background: #b58f52;
    box-shadow: 0 0 5px #b58f52, 0 0 15px #b58f52, 0 0 30px #b58f52,
      0 0 60px #b58f52;
  }

  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 60%;
    margin-top: 10%;
  }

  .title {
    font-size: 145px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, #024d2d 22%, #7d9b6c 24%, #024d2d 26%, #ceab4d 27%, #ceab4d 40%, #00331d 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    text-decoration: underline 14px #997c43;
  }

  .aboutMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    font-size: 50px;
    color: rgba(149, 211, 183, 0.644);
  }

  .daoMainBg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .daoMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 60%;
    margin-top: 10%;
  }

  .whyMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .visionMain {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10%;
  }

  .daoMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    margin-bottom: 90px;
    font-size: 50px;
    color: rgba(149, 211, 183, 0.644);
  }

  .whyMain p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    margin-bottom: 90px;
    font-size: 50px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    background: rgba(240, 240, 240, 0.075);
    padding: 45px;
    width: 80%;
    color: rgba(189, 186, 186, 0.644);
    text-shadow: 0 0 10px #ffffff80;
    text-shadow: 0 0 10px #ffffff80;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff80;
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.5234265581232493) 0%, rgba(146, 175, 89, 0.5234265581232493) 100%);
  }

  .content3Main {
    width: 30%;

  }

  .content3 {
    background: rgb(2, 77, 45);
    background: linear-gradient(299deg, rgba(2, 77, 45, 0.3749671743697479) 0%, rgba(103, 124, 61, 0.39457501750700286) 48%, rgba(206, 171, 77, 0.3889727766106442) 95%);
    color: rgba(230, 255, 244, 0.644);
    padding: 50px;
    border-radius: 50px;
    font-size: 45px;
  }



  .mainCards {
    display: flex;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .mainCardsSub {
    color: #ffe4b588;
    background: rgb(184, 126, 35);
    background: linear-gradient(94deg, rgba(184, 126, 35, 0.13967305672268904) 0%, rgba(242, 187, 97, 0.061241684173669486) 100%);
    border-radius: 50px;
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #b58f5265;
    width: 25%;
    margin-left: 60px;
    margin-right: 60px;
    padding: 90px;
  }

  .mainCardsSub img {
    width: 100%;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .mainCardsSub div {
    font-size: 50px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .visionSub {
    display: flex;
    justify-content: space-evenly;
    margin-top: -260px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .visionSub img {
    width: 50%;
  }

  .visionSub div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 48px;
    text-align: left;
    color: rgba(149, 211, 183, 0.644);
  }

  li::marker {
    color: #634f2c;
  }


  .textButton {
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .svAbout {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1%;
  }

  .gradient2 {
    background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Gentax', sans-serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 95px;
    width: 100%;
  }

  .svAbout div {
    margin-top: auto;
    margin-bottom: auto;
    width: 40%;
    font-size: 50px;
    text-align: left;
    color: rgba(149, 211, 183, 0.404);
  }

  .videoWidth {
    width: 1600px;
    height: 840px;
  }

  .vidT {
    font-weight: bolder;
  }

  .mintBoxMain {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .nft1 {
    width: 1100px;
  }

  .nft2 {
    width: 900px;
  }

  .mintBox div {
    text-align: center;
    font-size: 75px;
    color: #cae078;
    text-shadow: 0 0 10px #174b2e;
    font-family: 'Gentax', sans-serif;

  }

  .mintBox button {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease-in-out;
  }

  .mintBox button:hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: rgb(136, 136, 136);
  }

  .footer {
    background-color: #181818;
    color: #f2bb61;
    padding: 140px 0;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    background: linear-gradient(180deg, rgba(0,0,0,0.8651632528011204) 0%, rgba(0,0,0,0.5178243172268908) 51%, rgba(0,0,0,0.8819699754901961) 100%), url('./assets/3_asdasf112.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .footer img {
    width: 450px;
  }

  .footer a {
    color: #f2bb61;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer a:hover {
    color: #ffd04e;
    /* Change to desired hover color */
  }

  .footer p {
    margin: 30px 0;
    font-size: 50px;
  }
}